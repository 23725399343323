import usePricingModel from '@components/_hooks/usePricingModel';
import React from 'react';

export default function HeaderChoosePricingModel({ pricing_model }) {
  const { showPricingModel, setPricingModel, disabledToggle } = usePricingModel();
  
  const nonExhibitionStyles = {
	borderRadius: '20px',
	background:disabledToggle && pricing_model == 1 ? '#9a9393' : pricing_model === 0 ? 'white' : 'initial',
	color: pricing_model === 0 ? 'var(--color-primary)' : 'var(--color-primary-text)',
	cursor: 'pointer',
  }

  const exhibitionStyles = {
		borderRadius: '20px',
		background:disabledToggle && pricing_model == 0 ? '#9a9393' : pricing_model === 1 ? 'white' : 'initial',
		color: pricing_model === 1 ? 'var(--color-primary)' : 'var(--color-primary-text)',
		cursor: 'pointer',
  }

  return (
		showPricingModel && (
			<div
				className="pt-2 pl-2 pr-2"
				style={{
					display: 'flex',
					justifyContent: 'center', 
				}}
			>
				<div
					className="w-100 p-1 justify-space-between"
					style={{
						display: 'flex',
						backgroundColor: 'var(--color-primary)',
						borderRadius: '20px',
					}}
				>
					<div
						className="w-50 text-center mr-1"
						style={nonExhibitionStyles}
						onClick={() => setPricingModel(0)}
					>
						<span className="fw-bold d-block">Non-Exhibition</span>
						<span className="fs-12 d-block mb-2" style={{lineHeight: '10px'}}>standard pricing</span>
					</div>
				
					<div
						className="w-50 text-center align-content-center ml-1"
						style={exhibitionStyles}
						onClick={() => setPricingModel(1)}
					>
						<span className="fw-bold d-block">Exhibition</span>
						<span className="fs-12 d-block mb-2" style={{lineHeight: '10px'}}>all-inclusive pricing</span>
					</div>
				</div>
			</div>
		)
	);
}
